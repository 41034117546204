export const calculatePoints = (userBets, results) => {
    let totalPoints = 0;
    const pointsPerBet = {};

    for (const bet of userBets) {
        let points = 0;
        const candidatePoints = {};

        if (bet.category === 'winner') {
            const winnerId = results?.winner;
            const hasWon = winnerId && bet.candidateIds.includes(winnerId);
            points = hasWon ? 5 : 0;
            candidatePoints[winnerId] = points;
        } else {
            const categoryResults = results[bet.category] || [];
            points = bet.candidateIds.reduce((acc, candidateId) => {
                const point = categoryResults.includes(candidateId) ? 1 : 0;
                candidatePoints[candidateId] = point;
                return acc + point;
            }, 0);
        }

        pointsPerBet[bet.category] = candidatePoints;
        totalPoints += points;
    }

    return { totalPoints, pointsPerBet };
};