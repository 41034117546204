import React, { useState, useEffect } from 'react';
import { firestore } from '../../services/firebase';
import { collection, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../../styles/AdminPage.css';

const AdminPage = () => {
    const [candidates, setCandidates] = useState([]);
    const [results, setResults] = useState({
        top15: { 0: "" },
        top5: { 0: "" },
        winner: ""
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    // Check if user is admin
    useEffect(() => {
        const checkAdminStatus = async () => {
            if (!currentUser) {
                navigate('/');
                return;
            }

            const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
            if (!userDoc.exists() || userDoc.data().role !== 'admin') {
                navigate('/');
            }
        };

        checkAdminStatus();
    }, [currentUser, navigate]);

    // Fetch candidates and current results
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch candidates
                const candidatesSnapshot = await getDocs(collection(firestore, 'candidates'));
                const candidatesList = candidatesSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setCandidates(candidatesList);

                // Fetch current results
                const resultsDoc = await getDoc(doc(firestore, 'results', 'current'));
                if (resultsDoc.exists()) {
                    setResults(resultsDoc.data());
                }

                setLoading(false);
            } catch (err) {
                setError('Error fetching data');
                console.error(err);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Add the createIndices helper function
    const createIndices = (length) => {
        return Array.from({ length }, (_, i) => i);
    };

    // Helper function to check if a region is already selected
    const isRegionSelected = (category, region, currentIndex) => {
        const values = Object.entries(results[category]);
        return values.some(([index, value]) => value === region && parseInt(index) !== currentIndex);
    };

    const handleCandidateSelection = (category, candidateId) => {
        setResults(prev => {
            if (category === 'winner') {
                return { ...prev, winner: candidateId };
            }

            const updatedCategory = prev[category].includes(candidateId)
                ? prev[category].filter(id => id !== candidateId)
                : [...prev[category], candidateId];

            // Prevent selecting more than allowed number
            if ((category === 'top15' && updatedCategory.length > 15) ||
                (category === 'top5' && updatedCategory.length > 5)) {
                return prev;
            }

            return { ...prev, [category]: updatedCategory };
        });
    };

    const saveResults = async () => {
        try {
            await updateDoc(doc(firestore, 'results', 'current'), results);
            alert('Results saved successfully!');
        } catch (err) {
            setError('Error saving results');
            console.error(err);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="admin-page">
            <h2>Admin Panel - Results Management</h2>
            
            <div className="results-section">
                <div className="category-section">
                    <h3>Top 15 ({Object.keys(results.top15).length}/15)</h3>
                    <div className="candidates-grid">
                        {candidates.map(candidate => (
                            <div
                                key={candidate.id}
                                className={`candidate-item ${Object.values(results.top15).includes(candidate.region) ? 'selected' : ''}`}
                                onClick={() => handleCandidateSelection('top15', candidate.id)}
                            >
                                <img src={candidate.imageUrl} alt={candidate.name} />
                                <div className="candidate-info">
                                    <span>{candidate.region}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="category-section">
                    <h3>Top 5 ({Object.keys(results.top5).length}/5)</h3>
                    <div className="candidates-grid">
                        {candidates.filter(c => Object.values(results.top15).includes(c.region)).map(candidate => (
                            <div
                                key={candidate.id}
                                className={`candidate-item ${Object.values(results.top5).includes(candidate.region) ? 'selected' : ''}`}
                                onClick={() => handleCandidateSelection('top5', candidate.id)}
                            >
                                <img src={candidate.imageUrl} alt={candidate.name} />
                                <div className="candidate-info">
                                    <span>{candidate.region}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="category-section">
                    <h3>Winner</h3>
                    <div className="candidates-grid">
                        {candidates.filter(c => Object.values(results.top5).includes(c.region)).map(candidate => (
                            <div
                                key={candidate.id}
                                className={`candidate-item ${results.winner === candidate.id ? 'selected' : ''}`}
                                onClick={() => handleCandidateSelection('winner', candidate.id)}
                            >
                                <img src={candidate.imageUrl} alt={candidate.name} />
                                <div className="candidate-info">
                                    <span>{candidate.region}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <button 
                    className="save-button"
                    onClick={saveResults}
                >
                    Save Results
                </button>
            </div>
        </div>
    );
};

export default AdminPage;
