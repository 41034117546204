// components/MyBets.js
import React, { useState, useEffect, useCallback } from 'react';
import { firestore } from '../../services/firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { calculatePoints } from '../../utils/scoring';
import '../../styles/MyBets.css';

const MyBets = () => {
    const [userBets, setUserBets] = useState([]);
    const [candidates, setCandidates] = useState({});
    const [results, setResults] = useState(null);
    const { currentUser } = useAuth();

    const fetchCandidates = useCallback(async () => {
        try {
            const candidatesCollection = collection(firestore, 'candidates');
            const candidatesSnapshot = await getDocs(candidatesCollection);
            const candidatesMap = {};
            candidatesSnapshot.docs.forEach(doc => {
                candidatesMap[doc.id] = doc.data();
            });
            setCandidates(candidatesMap);
            console.log('Candidates fetched:', candidatesMap);
        } catch (error) {
            console.error('Error fetching candidates:', error);
        }
    }, []);

    const fetchUserBets = useCallback(async () => {
        if (!currentUser) return;

        try {
            const betsCollection = collection(firestore, 'bets');
            const userBetsQuery = query(betsCollection, where('userId', '==', currentUser.uid));
            const userBetsSnapshot = await getDocs(userBetsQuery);
            const userBetsList = userBetsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setUserBets(userBetsList);
            console.log('User bets fetched:', userBetsList);
        } catch (error) {
            console.error('Error fetching user bets:', error);
        }
    }, [currentUser]);

    const fetchResults = useCallback(async () => {
        try {
            const resultsDoc = doc(firestore, 'results', 'current');
            const resultsSnapshot = await getDoc(resultsDoc);
            if (resultsSnapshot.exists()) {
                setResults(resultsSnapshot.data());
                console.log('Results fetched:', resultsSnapshot.data());
            }
        } catch (error) {
            console.error('Error fetching results:', error);
        }
    }, []);

    useEffect(() => {
        if (currentUser) {
            console.log('Fetching user bets and candidates...');
            fetchUserBets();
            fetchCandidates();
            fetchResults();
        }
    }, [currentUser, fetchUserBets, fetchCandidates, fetchResults]);


    const renderBet = (bet) => {
        const candidateNames = bet.candidateIds.map(id => candidates[id]?.region || id);
        const { pointsPerBet } = calculatePoints([bet], results || {});
        const betPoints = Object.values(pointsPerBet[bet.category] || {}).reduce((a, b) => a + b, 0);

        const categoryDisplayNames = {
            top5: 'Top 5',
            top15: 'Top 15',
            winner: 'Winner'
        };

        const displayName = categoryDisplayNames[bet.category] || bet.category;

        return (
            <div key={bet.id} className="bet-item">
                <h3>{displayName}</h3>
                <ul>
                    {candidateNames.map((region, index) => {
                        const candidateId = bet.candidateIds[index];
                        const isCorrect = results && results[bet.category]?.includes(candidateId);
                        return (
                            <li key={candidateId}>
                                {region} {isCorrect ? '🏆' : '❌'}
                            </li>
                        );
                    })}
                </ul>
                <p>Points: <b>{betPoints}</b></p>
                <p>Submitted on: {new Date(bet.timestamp.seconds * 1000).toLocaleString()}</p>
            </div>
        );
    };

    if (!candidates || Object.keys(candidates).length === 0) {
        return <div>Loading...</div>;
    }

    const { totalPoints } = userBets.length > 0 ? calculatePoints(userBets, results || {}) : { totalPoints: 0 };

    return (
        <div className="my-bets-container">
            {userBets.length === 0 ? (
                <div className="no-bets-message">
                    <h2>Pas encore de paris</h2>
                    <p>Tu n'as pas encore placé de paris. Rends-toi sur la page Soumettre des paris pour faire tes prédictions !😊</p>
                </div>
            ) : (
                <>
                    <h1>🏆 Points Totaux : {totalPoints}</h1>
                    <div className="bets-list">
                        {userBets.map(bet => renderBet(bet))}
                    </div>
                </>
            )}
        </div>
    );
};

export default MyBets;